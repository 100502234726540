import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody } from "reactstrap";

const studioProcessesData = [
  {
    id: 1,
    title: "Solicitud de Servicio",
    desc:
      "Contáctanos a través de nuestro sitio web, correo electrónico o teléfono para solicitar un servicio de transporte.",
    link: "#",
  },
  {
    id: 2,
    title: "Planificación de Ruta",
    desc:
      "Nuestro equipo de logística planifica la mejor ruta para tu envío, optimizando tiempos y recursos.",
    link: "#",
  },
  {
    id: 3,
    title: "Recolección de Mercancía",
    desc:
      "Recogemos tu carga en el punto de origen, asegurándonos de que esté bien embalada y protegida.",
    link: "#",
  },
  {
    id: 4,
    title: "Transporte y Seguimiento",
    desc:
      "Tu carga es transportada con la máxima seguridad y puedes hacer seguimiento en tiempo real a través de nuestra plataforma online.",
    link: "#",
  },
  {
    id: 5,
    title: "Entrega",
    desc:
      "Entregamos tu carga en el destino final, cumpliendo con los tiempos acordados y asegurando la satisfacción del cliente.",
    link: "#",
  },
];

class ProcessBox extends Component {
  
  render() {
    return (
      <React.Fragment>
        {studioProcessesData.map((process, key) => (
          <Col md="4" className="mt-4 pt-2" key={key}>
            <Card className="work-process border-0 rounded shadow">
              <CardBody>
                <h4 className="title">{process.title}</h4>
                <p className="text-muted">{process.desc}</p>
                <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                  <li className="step h1 mb-0 fw-bold">
                    Paso {key + 1}.
                  </li>
                  <li className="step-icon">
                    <i
                      className={
                        key + 1 === length
                          ? "mdi mdi-check-all mdi-36px"
                          : "mdi mdi-chevron-double-right mdi-36px"
                      }
                    ></i>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ProcessBox;
