import React, {useState, useEffect} from "react";

import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import contact from "../../assets/images/contact.svg";

import emailjs from "@emailjs/browser";
import { emailjs_settings, google } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";


const ContactUs = () => {

  const recaptchaRef = React.createRef();

  const [succeMsg, setSucceMsg] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  useEffect(() => emailjs.init(emailjs_settings.PUBLIC_KEY), []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      message: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor ingresa tu nombre"),
      email: Yup.string().required("Por favor ingresa tu correo"),
    }),
    onSubmit: async (values) => {
      setSendingMessage(true);

      const token = await recaptchaRef.current.executeAsync();
      try {
        await emailjs.send(emailjs_settings.SERVICE_ID, emailjs_settings.TEMPLATE_ID, {
          from_name: values['name'],
          from_email: values['email'],
          message: values['message'],
          'g-recaptcha-response': token,
        });
        setSucceMsg(true);
      } catch (error) {
        console.log(error);
      } finally {
        setSendingMessage(false);
        validation.resetForm();
      }
    }
  });

  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Contáctate con nosotros"
          desc="Busca la mejora manera de comunicarte con nosotros para responder a tus dudas"
        />

        <Row className="align-items-center">
          <Col
            lg="5"
            md={{ size: 6, order: 1 }}
            xs={{ order: 2 }}
            className="mt-0 pt-2 pb-4"
          >
            <Card
              className="rounded shadow border-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <CardBody>
                <h4 className="card-title">Envíanos tu consulta ahora mismo</h4>
                <div className="custom-form bg-white mt-4">
                  <div id="message"></div>
                  <Alert
                    color="info"
                    isOpen={succeMsg}
                    toggle={() => {
                      setSucceMsg(false);
                    }}
                  >
                    ¡Tu mensaje ha sido enviado exitosamente!
                  </Alert>
                  
                  <Form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Tu Nombre <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Tu Nombre :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Tu Correo <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="email2"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Tu Correo :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}                          
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Mensaje</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <textarea
                            name="message"
                            id="message"
                            rows="4"
                            className="form-control ps-5"
                            placeholder="Tu Mensaje :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ""}
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={google.RECAPTCHA_KEY}
                    />
                    <Row>
                      <Col sm="12" className="text-center">
                        <div className="d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            disabled={sendingMessage}
                            className="submitBnt btn btn-primary"
                            value="Enviar Mensaje"
                          />
                          <div id="simple-msg"></div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
            <Card className="border-0">
              <CardBody className="p-0">
                <img src={contact} className="img-fluid" alt="Transportes Pacari Express" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;
