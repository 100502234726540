import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";

// import Images
//import classic02 from "../../assets/images/logistics/why.png";
import classic02 from "../../assets/images/logistics/warehouse.jpg";

export default class Tools extends Component {
  render() {
    return (
      <React.Fragment>
        {/*<Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={amico} className="img-fluid" alt="" />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-4">
                  ¿Por Qué Elegir PacariExpress?
                </h4>

                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Experiencia y Profesionalismo
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Más de 20 años en el mercado nos avalan como líderes en transporte de carga.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Cobertura Extensa
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Llegamos a cada rincón de Chile, ofreciendo una red de transporte confiable y eficiente.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Tecnología Avanzada
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Sistemas de seguimiento y seguridad de vanguardia para que siempre sepas dónde está tu carga.
                </p>
                <h5>
                  <i className="uil uil-arrow-circle-right text-primary me-1"></i>{" "}
                  Atención al Cliente
                </h5>
                <p className="text-muted para-desc ms-4 ps-2">
                  Un equipo siempre dispuesto a ayudarte, ofreciendo soporte y soluciones rápidas a cualquier inconveniente.
                </p>
              </div>
            </Col>
          </Row>
        </Container>*/}
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col
              lg={{ size: 5, order: 1 }}
              xs={{ order: 2 }}
              className="mt-4 pt-2 mt-lg-0 pt-lg-0"
            >
              <div className="section-title me-lg-4">
                <h1 className="title mb-3 text-center">¿Por Qué Elegirnos?</h1>
                <p className="para-desc text-muted text-center">
                  Nuestro compromiso con la excelencia y la satisfacción del cliente nos distingue como el líder en el mercado de transporte de carga.
                </p>

                <Row className="p-3">
                  <Col xs="12">
                    <div className="d-flex align-items-center pt-4">
                      <h2>
                        <i>
                          <FeatherIcon
                            icon="award"
                            className="fea icon-m-md text-primary"
                          />
                        </i>
                      </h2>
                      <div className="ms-3">
                        <h5>Experiencia y Profesionalismo</h5>
                        <p className="text-muted mb-0">Más de 15 años en el mercado nos avalan como líderes en transporte de carga.</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="d-flex align-items-center pt-4">
                      <h2>
                        <i>
                          <FeatherIcon
                            icon="maximize-2"
                            className="fea icon-m-md text-primary"
                          />
                        </i>
                      </h2>
                      <div className="ms-3">
                        <h5>Cobertura Extensa</h5>
                        <p className="text-muted mb-0">Llegamos a cada rincón de Chile, ofreciendo una red de transporte confiable y eficiente.</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="d-flex align-items-center pt-4">
                      <h2>
                        <i>
                          <FeatherIcon
                            icon="cpu"
                            className="fea icon-m-md text-primary"
                          />
                        </i>
                      </h2>
                      <div className="ms-3">
                        <h5>Tecnología Avanzada</h5>
                        <p className="text-muted mb-0">Sistemas de seguimiento y seguridad de vanguardia para que siempre sepas dónde está tu carga.</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="d-flex align-items-center pt-4">
                      <h2>
                        <i>
                          <FeatherIcon
                            icon="phone-call"
                            className="fea icon-m-md text-primary"
                          />
                        </i>
                      </h2>
                      <div className="ms-3">
                        <h5>Atención al Cliente</h5>
                        <p className="text-muted mb-0">Un equipo siempre dispuesto a ayudarte, ofreciendo soporte y soluciones rápidas a cualquier inconveniente.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
              <div className="saas-feature-shape-right position-relative">
                <img
                  src={classic02}
                  className="img-fluid mx-auto d-block rounded shadow"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
