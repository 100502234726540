import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

const Solutions = () => {
    return (
        <React.Fragment>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <div className="features-absolute">
                            <Row className="g-2">
                                <Col md={6} className="col-12">
                                    <div className="card features feature-primary feature-full-bg rounded shadow p-4 position-relative overflow-hidden border-0">
                                        <span className="h2 icon-color">
                                            <FeatherIcon
                                                icon="truck"
                                            />
                                        </span>
                                        <div className="card-body p-0 content">
                                            <h5 className="mb-3">Servicio transporte</h5>
                                            <p className="para text-muted mb-3">Soluciones de transporte rápidas y seguras a distintos puntos de Chile.</p>
                                        </div>
                                        <div className="position-absolute top-0 end-0">
                                            <FeatherIcon
                                                icon="truck"
                                                className="opacity-05 me-4 mt-4"
                                                size="80"
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col md={6} className="col-12">
                                    <div className="card features feature-primary feature-full-bg rounded shadow p-4 position-relative overflow-hidden border-0">
                                        <span className="h2 icon-color">
                                            <i className="uil uil-store"></i>
                                            <FeatherIcon
                                                icon="home"
                                            />
                                        </span>
                                        <div className="card-body p-0 content">
                                            <h5 className="mb-3">Almacenaje logístico</h5>
                                            <p className="para text-muted mb-3">Tus productos almacenados de manera segura, evitando daños, robos o pérdidas</p>
                                        </div>
                                        <div className="position-absolute top-0 end-0">
                                            <FeatherIcon
                                                icon="home"
                                                className="opacity-05 me-4 mt-4"
                                                size="80"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default Solutions;