import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import artDesign from "../../assets/images/icon/art-and-design.svg";
import smartphone from "../../assets/images/icon/smartphone.svg";
import clock from "../../assets/images/icon/clock.svg";

const servicesData = [
    {
        id: 1,
        img: artDesign,
        title: "Cobertura Nacional",
        content: "Desde Arica hasta Punta Arenas, cubrimos todo el territorio chileno. No importa dónde necesites enviar tu mercancía, PacariExpress está aquí para ayudarte."
    },
    {
        id: 2,
        img: smartphone,
        title: "Transporte Seguro y Confiable",
        content: "Nos enorgullece contar con una flota de vehículos equipados con la última tecnología en seguridad y seguimiento, asegurando que tu carga esté siempre protegida."
    },
    {
        id: 3,
        img: clock,
        title: "Entregas Puntuales",
        content: "Entendemos la importancia de la puntualidad en el mundo de los negocios. Por eso, nos comprometemos a cumplir con los tiempos de entrega acordados, sin excepciones."
    },
];

const Services = () => {
    return (
        <React.Fragment>
            <Container className="mt-0">
                <Row className="justify-content-center">
                    <Col className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title mb-4">Nuestros Servicios</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Empieza a trabajar con Transportes a <span className="text-primary fw-bold">Pacari Express</span>, tenemos todo para garantizar el mejor servicio.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {servicesData.map((item, key) => (
                        <Col key={key} lg={4} md={6} className="mt-4 pt-2">
                            <Card className="features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                                <div className="rounded d-inline-block">
                                    <img src={item.img} className="avatar avatar-small" alt="" />
                                </div>

                                <CardBody className="card-body p-0 content">
                                    <h5 className="mt-4 mb-3"><Link to="#" className="title text-dark">{item.title}</Link></h5>
                                    <p className="text-muted">{item.content}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default Services;