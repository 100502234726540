import React, { Component, Suspense } from "react";
import { Col, Container, Row } from 'reactstrap';

//Import Icons
import { Link } from 'react-router-dom';

//import images
import icon from '../../assets/images/logo-light.png';

//Import Switcher
import BackToTop from "../../components/Layout/backToTop";

import FeatherIcon from 'feather-icons-react';


const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Footer5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [
        { listItem: "Privacy" },
        { listItem: "Terms" },
        { listItem: "FAQs" },
        { listItem: "Contact" },
      ]
    }
  }
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <footer className="footer">
            <Container>
              <Row className="justify-content-center">
                <Col className="col-12">
                  <div className="footer-py-60 text-center">
                    <Row className="py-5">
                      <Col md={4}>
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <FeatherIcon
                              icon="phone"
                              className="d-block rounded h3 mb-0"
                              size="24"
                            />
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">Teléfono</h5>
                            <p className="text-muted">Llámanos para solucionar tus dudas</p>
                            <Link to="tel:+152534-468-854" className="text-foot">+56 2 222-222-2</Link>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <FeatherIcon
                              icon="mail"
                              className="d-block rounded h3 mb-0"
                              size="24"
                            />
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">Correo</h5>
                            <p className="text-muted">Envíanos un correo y te responderemos enseguida</p>
                            <Link to="mailto:contacto@pacariexpress.com" className="text-foot">contacto@pacariexpress.com</Link>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <FeatherIcon
                              icon="map-pin"
                              className="d-block rounded h3 mb-0"
                              size="24"
                            />
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">Ubicación</h5>
                            <p className="text-muted">Avenida Vizcaya 17011, Pudahuel <br />Santiago, Chile</p>
                            <Link to="https://maps.app.goo.gl/efiQW7F4EdXos4U36" className="text-foot" target="_blank">Ver en Google map</Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="footer-py-30 footer-bar bg-footer">
              <Container className="text-center">
                <Row className="align-items-center justify-content-between">
                  <Col lg={3} md={2} sm={3}>
                    <div className="text-sm-start">
                      <Link to="#" className="logo-footer">
                        <img src={icon} height="34" alt="" />
                      </Link>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled footer-list terms-service mb-0">
                      <li className="list-inline-item mb-0"><Link to="/#solutions" className="text-foot me-2">¿Quiénes somos?</Link></li>{" "}
                      <li className="list-inline-item mb-0"><Link to="/#services" className="text-foot me-2">Servicios</Link></li>{" "}
                      <li className="list-inline-item mb-0"><Link to="/#contact" className="text-foot me-2">Contacto</Link></li>{" "}
                      <li className="list-inline-item mb-0"><Link to="/tracking" className="text-foot">Seguimiento</Link></li>{" "}
                    </ul>
                  </Col>

                  <Col lg={3} md={4} sm={3} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="text-sm-end">
                      <p className="mb-0 text-foot">© {(new Date().getFullYear())} {" "}
                        Transportes Pacari Express. Diseñado por Optiroute
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>

          <BackToTop />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Footer5;
