import React, { useEffect } from 'react';
import Home from "./Home";
import Solutions from "./Solutions";
import AboutUs from './AboutUs';
import CTA from "./CTA";
import Services from './Services';
import Services2 from './Services2';
import Why from "./Why";
import WorkProcess from "./WorkProcess";
import Footer5 from './Footer5';
import Popup from '../../components/Layout/popup';
import ContactUs from "./ContactUs";

import bgImg from "../../assets/images/logistics/why.png";
import bgLogo from "../../assets/images/pacari-logo-light.png";

const Index = () => {
    useEffect(() => {
        document.body.classList = "";
        document.querySelectorAll("#buyButton").forEach((navLink) => {
            navLink.classList.add("btn-light")
            navLink.classList.remove("btn-soft-primary");
            document.getElementById("top-menu")?.classList.add("nav-light");
        });
        window.addEventListener("scroll", scrollNavigation, true);
    });

    const scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
            document.querySelector(".settingbtn")?.classList.remove("btn-light");
            document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
            document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");

            document.getElementById("topnav")?.classList.add("nav-sticky");
        } else {
            document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
            document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
            document.querySelector(".shoppingbtn")?.classList.add("btn-light");
            document.querySelector(".settingbtn")?.classList.add("btn-light");
            document.getElementById("topnav")?.classList.remove("nav-sticky");
        }
    };
    return (
        <React.Fragment>
            <Home />
            <Solutions />
            <section className="section" id="solutions">
                <AboutUs />
            </section>
            <section className="section" id="work" style={{ background: `url(${bgImg}) center center` }}>
                <div className="bg-overlay-white"></div>
                <WorkProcess />
            </section>
            <section className="section" id="services">
                <Services />
            </section>
            <Why />
            <CTA />
            <section className="section" id="contact" >
                <ContactUs />
            </section>
            <Footer5 />
            {/*<Popup/>*/}
        </React.Fragment>
    );
}

export default Index;