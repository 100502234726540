module.exports = {
  google: {
    RECAPTCHA_KEY: "6Lf-jPMpAAAAAJxtZ-JYA-iXwmLmQfv0_pcQY37A",
  },
  optiroute: {
    ENTERPRISE_UUID: "a0d2df13-b74c-42e9-9260-badc40396360",
  },
  api: {
    API_URL: "https://testing.optiroute.cl/api/v1/",
  },
  emailjs_settings: {
    TEMPLATE_ID: "template_9waeoef",
    SERVICE_ID: "service_eapn5pq",
    PUBLIC_KEY: "dkT0W-Vv1nLbFAmLf",
  }
};
