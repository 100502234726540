import React from 'react';
import {
	Badge
} from "reactstrap";
/*
DELETED = -4
TEMPORARY = -3
IMPORTED = -2
CANCELLED = -1	
REVIEWING = 0
SCHEDULED = 1
ONGOING = 2
DELIVERED = 3
ARRIVED = 4
SKIPPED = 5
ONROUTE = 6
*/

//Constants
const OrderStatus = ({order}) => {
	if (order.status === 0) {
		return <Badge className="badge rounded-pill bg-primary h1">Pedido Creado</Badge>;
	} else if (order.status === 1) {
		return <Badge className="badge rounded-pill bg-info h1">Sucursal de destino</Badge>;
	} else if (order.status === 2) {
		return <Badge className="badge rounded-pill bg-info h1">En Ruta de entrega</Badge>;
	} else if (order.status === 3) {
		return <Badge className="badge rounded-pill bg-success h1">Pedido entregado</Badge>;
	} else if (order.status === 4) {
		return <Badge className="badge rounded-pill bg-info h1">En Ruta de entrega</Badge>;
	} else if (order.status === 5) {
		return <Badge className="badge rounded-pill bg-danger h1">Pedido no entregado</Badge>;
	} else if (order.status === 6) {
		return <Badge className="badge rounded-pill bg-info h1">En Ruta de entrega</Badge>;
	} else if (order.status === -1) {
		return <Badge className="badge rounded-pill bg-danger h1">Pedido {order.status_text}</Badge>;
	} else if (order.status === -4) {
		return <Badge className="badge rounded-pill bg-dark h1">Pedido {order.status_text}</Badge>;
	}
};

export default OrderStatus;