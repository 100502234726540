import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import logistics03 from "../../assets/images/logistics/03.jpg";
import logistics06 from "../../assets/images/logistics/06.jpg";
import logistics07 from "../../assets/images/logistics/07.jpg";
import logistics08 from "../../assets/images/logistics/08.jpg";

import bgLogo from "../../assets/images/pacari-logo-light.png";


const AboutUs = () => {
    const imageSectiondata = [
		{
            id: 8,
            img: logistics08,
		},
		{
            id: 2,
            img: logistics06,
		},
        {
            id: 3,
            img: logistics03,
        },
        {
            id: 6,
            img: logistics07,
        },
        
	];

    return (
        <React.Fragment>
            <Container className="mt-100 mt-60">
                <Row className="align-items-center">
                    <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <Row id="grid">
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 2 }}>
                                <Masonry columnsCount={2}>
                                    {imageSectiondata.map((item, key) => (
                                    <Col key={key} className="col-12 spacing picture-item">
                                        <img src={item.img} className="img-fluid shadow seo-hero-widget rounded-md" alt="" />
                                    </Col>
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </Row>
                    </Col>

                    <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0" style={{ background: `url(${bgLogo}) top no-repeat`, backgroundSize: '100% auto' }}>
                        <div className="section-title ms-lg-4">
                            <h4 className="title">Transportes <span className="text-primary">Pacari Express</span><br /> </h4>
                            <h6 className="mb-4">Tu Socio Confiable en Transporte</h6>
                            <p className="text-muted">En <span className="text-primary fw-bold">Pacari Express</span>, nos dedicamos a ofrecer soluciones de transporte rápidas y seguras a distintos puntos de Chile. Con una flota moderna y personal capacitado, garantizamos que tus envíos lleguen a su destino a tiempo y en perfectas condiciones.</p>
                            <Link to="/#work" className="btn btn-primary mt-3">Ver más <i className="uil uil-angle-right-b"></i></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default AboutUs;