import React from 'react'
import { Navigate } from "react-router-dom";

import Logistics from "../pages/Logistics/index";

const routes = [
  { path: "/", component: <Logistics />, isTopbarDark: false },
  

  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  

];

export default routes;
