import React, { useState, useCallback } from "react";
import { Container, Row, Col, Form, Button } from 'reactstrap';
import { Link, useParams, useNavigate } from "react-router-dom";

//Import Slick Slider
import Slider from "react-slick";

//Import Slick Slider CSS
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import logistics01 from "../../assets/images/logistics/01.jpg";
import logistics02 from "../../assets/images/logistics/02.jpg";
import logistics03 from "../../assets/images/logistics/03.jpg";


const logisticsHomeData = [
    {
      id: 1,
      img: logistics01,
      titleLine1: "Transportes Pacari Express",
      titleLine2: "Tu Socio Confiable en Transporte",
      desc: "Ingresa tu número de seguimiento aquí:",
    },
    {
      id: 2,
      img: logistics02,
      titleLine1: "Transportes Pacari Express",
      titleLine2: "Tu Socio Confiable en Transporte",
      desc: "Ingresa tu número de seguimiento aquí:",
    },
    {
      id: 3,
      img: logistics03,
      titleLine1: "Transportes Pacari Express",
      titleLine2: "Tu Socio Confiable en Transporte",
      desc: "Ingresa tu número de seguimiento aquí:",
    },
];

const Home = () => {

    const navigate = useNavigate();

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 400,
        fade: true,
    };

    const [search, setSearch] = useState(null);
	const [searchPressed, setSearchPressed] = useState(false);

    const onSearchPressed = useCallback((value) => {
		if(search && search !== ""){
			setSearchPressed(true);
			navigate(`/tracking/${search}`);
		}
	});

	const onSearchChange = useCallback((value) => {
		setSearch(value);
	});

    const slides = logisticsHomeData.map((item, key) => {
        return (
            <React.Fragment key={key}>
                <section className="border-bottom agency-wrapper d-table w-100" data-bs-ride="carousel">
                    <div
                        id="carouselExampleInterval"
                    >
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${item.img})` }}>
                                    <div className="bg-overlay"></div>
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col className="col">
                                                <div className="title-heading position-relative mt-4 text-center" style={{ zIndex: 1 }}>
                                                    <h1 className="display-4 text-white title-dark mb-1">Transportes <span className="fw-bold">Pacari Express</span></h1>
                                                    <h3 className="display-7 text-white title-dark mb-1">Tu Socio Confiable en Transporte</h3>
                                                    <p className="para-desc mx-auto text-white-50">Ingresa el número de seguimiento de tu envío aquí:</p>
                                                    <div className="text-center subcribe-form pt-0 animated fadeInUpBig animation-delay-11">
                                                        <Form>
                                                            <input
                                                                type="text"
                                                                id="reference"
                                                                name="reference"
                                                                className="rounded"
                                                                placeholder="Código de seguimiento del pedido"
                                                                required
                                                                onChange={(e) => onSearchChange(e.target.value)}
                                                            />
                                                            <Button 
                                                                type="submit"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    onSearchPressed(e.target.value);
                                                            }}>
                                                                Buscar
                                                            </Button>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    });
    return (
        <React.Fragment>
            <section className="home-slider position-relative">
                <Slider className="carousel slide carousel-fade" {...settings}>
                    {slides}
                </Slider>
            </section>
        </React.Fragment>
    );
};

export default Home;