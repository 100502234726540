import { api } from "../config";
import { omitBy, isNull } from "lodash";
// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const orderApi = createApi({
	reducerPath: 'pacariExpressApi',
	tagTypes: ['Orders'],
	baseQuery: fetchBaseQuery({
		baseUrl: api.API_URL,
	}),
	endpoints: (builder) => ({

		getTrackingInfo: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `public-service-requests/?${params}`;
			},
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Orders', id }];
			}
		}),
		
	}),
})

export const {
	useGetTrackingInfoQuery
} = orderApi