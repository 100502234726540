// React Basic and Bootstrap
import React, { useState } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";

//Import Components
import ProcessBox from "../../components/Shared/ProcessBox";

const WorkProcess = () => {

  const [modal, setModal] = useState(false);

  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <Row className="justify-content-center">
          <Col className="col-12 text-center">
            <div className="section-title mb-4 mt-4 pb-2">
              <h4 className="title mb-4">¿Cómo Trabajamos?</h4>
              <p className="text-muted para-desc mx-auto mb-0">En Transportes <span className="text-primary fw-bold">Pacari Express</span> contamos con un flujo operacional controlado con la mejor tecnología.</p>
            </div>
          </Col>
        </Row>

        <Row>
          {/* process box */}
          <ProcessBox />
        </Row>
      </Container>

    </React.Fragment>
  );
}

export default WorkProcess;
